.checkbox-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;
}

.custom-checkbox {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #edc773;
}

.checkbox-input {
    width: 16px;
    height: 16px;
    cursor: pointer;
}

.checkbox-label {
    font-size: 0.9em;
}

.custom-checkbox .checkbox-input {
    appearance: none;
    -webkit-appearance: none;
    width: 16px;
    height: 16px;
    border: 2px solid #edc773;
    border-radius: 3px;
    margin-right: 8px;
    position: relative;
    cursor: pointer;
    flex-shrink: 0;
}

.custom-checkbox .checkbox-input:checked {
    background-color: #edc773;
}

.custom-checkbox .checkbox-input:checked::before {
    content: '';
    position: absolute;
    left: 5px;
    top: 2px;
    width: 4px;
    height: 8px;
    border: solid #121921;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.custom-checkbox:hover .checkbox-input {
    border-color: #edc773;
}

.custom-checkbox:hover .checkbox-label {
    color: #edc773;
}

/* Column layout fixes */
.advanced-search-container .col-md {
    display: block;
}

.filter-section-title {
    color: #edc773;
    margin-bottom: 15px;
    font-weight: 500;
}

.search-container {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
}

.search-input-wrapper {
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
    min-width: 0;
}

.search-input {
    width: 100%;
    padding-right: 40px !important;
    flex: 1;
}

.filter-button {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    padding: 5px;
    color: #edc773;
    cursor: pointer;
    z-index: 2;
    min-width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.filter-button:hover {
    color: #fff;
}

.filter-button .active {
    color: #fff;
}

.filter-summary {
    margin-top: 8px;
    padding: 8px 12px;
    background-color: #121921;
    border: 1px solid rgba(237, 199, 115, 0.2);
    border-radius: 4px;
}

.summary-text {
    color: #edc773;
    font-size: 0.9em;
}

.summary-hint {
    color: #888;
    font-size: 0.8em;
    margin-top: 2px;
}

/* Collapse styles */
.collapse {
    position: relative;
    width: 100%;
}

.collapse.show {
    display: block;
}

/* .advanced-search-container {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #121921;
    border: 1px solid #edc773;
    border-radius: 4px;
    padding: 20px;
    margin-top: 10px;
    z-index: 1000;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
} */
.advanced-search-container {
    position: relative;
    padding: 20px;
    background-color: #121921;
    border-radius: 8px;
    margin-top: 15px;
    z-index: 1000;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    display: none;
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.advanced-search-container.show {
    display: block;
    opacity: 1;
    transform: translateY(0);
}

.close-filter-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: #edc773;
    font-size: 24px;
    line-height: 1;
    padding: 5px 10px;
    cursor: pointer;
    transition: color 0.2s ease;
    z-index: 1001;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.close-filter-button:hover {
    color: #fff;
    background-color: rgba(237, 199, 115, 0.1);
    border-radius: 50%;
}
/* .close-filter-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: #edc773;
    font-size: 24px;
    cursor: pointer;
    padding: 0;
    line-height: 1;
}

.close-filter-button:hover {
    color: #fff;
} */

.checkbox-with-info {
    display: flex;
    align-items: center;
    gap: 8px;
}

.info-icon {
    color: #edc773;
    font-size: 14px;
    cursor: help;
    transition: color 0.2s ease;
}

.info-icon:hover {
    color: #fff;
}

.info-icon-small {
    color: #edc773;
    font-size: 12px;
    cursor: help;
    transition: color 0.2s ease;
    margin-right: 2px;
    vertical-align: middle;
}

.info-icon-small:hover {
    color: #fff;
}

/* Override Bootstrap tooltip styles */
.tooltip.show {
    opacity: 1;
}

.tooltip-inner {
    background-color: #121921;
    color: #edc773;
    border: 1px solid #edc773;
    padding: 8px 12px;
    max-width: 300px;
    text-align: left;
    font-size: 0.9em;
    line-height: 1.4;
}

.tooltip.bs-tooltip-end .tooltip-arrow::before {
    border-right-color: #edc773;
}

.tooltip.bs-tooltip-bottom .tooltip-arrow::before {
    border-bottom-color: #edc773;
}