
.content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
#text-input {
  width: 600px;
  margin-right: 2px;
}


.image img { height: 100%; object-fit: cover; }

@media only screen and (min-width: 992px) {
  nav#menu.main-menu {
    display: block !important;
  }

  #mm-menu.main-menu {
    display: none !important;
  }

  header .btn_mobile {
    display: none !important;
  }

  /* Menu */
  .main-menu {
    position: relative;
    z-index: 9;
    width: auto;
    top: 12px;
    right: 15px;
    float: right;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    color: #fff;
  }
  .main-menu ul,
  .main-menu ul li {
    position: relative;
    display: inline-block;
    margin: 0;
    padding: 0;
  }
  .main-menu ul a,
  .main-menu ul li a {
    position: relative;
    margin: 0;
    padding: 0;
    display: block;
    padding: 5px 10px;
    white-space: nowrap;
  }

  /*First level styles */
  .main-menu > ul > li span > a {
    color: #fff;
    padding: 0 8px 15px 8px;
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: 500;
  }

  header.sticky .main-menu > ul > li span > a {
    color: #444;
  }
  header.sticky .main-menu > ul > li span:hover > a {
    opacity: 1;
    color: #fc5b62;
  }

  /* Submenu*/
  .main-menu ul ul {
    position: absolute;
    z-index: 1;
    visibility: hidden;
    left: 3px;
    top: 100%;
    margin: 0;
    display: block;
    padding: 0;
    background: #fff;
    min-width: 200px;
    -webkit-box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.175);
    -moz-box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.175);
    box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.175);
    transform: scale(0.4);
    transform-origin: 10% top;
    transition: 0.15s linear, 0.1s opacity cubic-bezier(0.39, 0.575, 0.565, 1), 0.15s transform cubic-bezier(0.1, 1.26, 0.83, 1);
    opacity: 0;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
  }
  .main-menu ul ul:before {
    bottom: 100%;
    left: 15%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: #fff;
    border-width: 7px;
    margin-left: -7px;
  }
  .main-menu ul ul li {
    display: block;
    height: auto;
    padding: 0;
  }
  .main-menu ul ul li a {
    font-size: 12px;
    font-size: 0.75rem;
    font-weight: 500;
    color: #555;
    border-bottom: 1px solid #ededed;
    display: block;
    padding: 15px 10px;
    line-height: 1;
  }

  .main-menu ul li:hover > ul {
    padding: 0;
    visibility: visible;
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
    -webkit-transition-duration: 0s, 0.2s, 0.2s;
    transition-duration: 0s, 0.2s, 0.2s;
  }

  .main-menu ul ul li:first-child a:hover {
    -webkit-border-radius: 3px 3px 0 0;
    -moz-border-radius: 3px 3px 0 0;
    -ms-border-radius: 3px 3px 0 0;
    border-radius: 3px 3px 0 0;
  }
  .main-menu ul ul li:last-child a {
    border-bottom: none;
  }
  .main-menu ul ul li:last-child a:hover {
    -webkit-border-radius: 0 0 3px 3px;
    -moz-border-radius: 0 0 3px 3px;
    -ms-border-radius: 0 0 3px 3px;
    border-radius: 0 0 3px 3px;
  }
  .main-menu ul ul li:hover > a {
    background-color: #f9f9f9;
    color: #fc5b62;
    padding-left: 18px;
  }
}
/* Submenu 2nd level right */
.main-menu ul ul.second_level_right {
  left: auto;
  right: 3px;
}
.main-menu ul ul.second_level_right:before {
  right: 15%;
  left: auto;
}

/* Submenu 3rd level */
.main-menu ul ul li span a {
  font-weight: 500 !important;
}
.main-menu ul ul li span a:hover {
  color: #fc5b62;
  padding-left: 18px;
  opacity: 1;
}
.main-menu ul ul li span a:after {
  font-family: 'ElegantIcons';
  content: "\35";
  float: right;
  font-size: 16px;
  font-size: 1rem;
  margin-top: -2px;
}

.main-menu ul ul ul {
  position: absolute;
  border-top: 0;
  z-index: 1;
  height: auto;
  left: 100%;
  top: 0;
  margin: 0;
  padding: 0;
  background: #fff;
  min-width: 190px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}
.main-menu ul ul ul.third_level_left {
  right: 100%;
  left: auto;
}
.main-menu ul ul ul li a {
  border-bottom: 1px solid #ededed !important;
}
.main-menu ul ul ul li:last-child a {
  border-bottom: none !important;
}

/* Arrows top 3rd level*/
.main-menu ul ul ul:before {
  border-width: 0;
  margin-left: 0;
}

/* All styles for screen size under 991px */
@media only screen and (max-width: 991px) {
  nav#menu {
    display: none !important;
  }

  .mm-menu {
    background: #fff;
  }

  .main-menu {
    top: 0;
    right: 0;
    float: none;
  }

  ul.mm-listview {
    line-height: 25px;
  }
  ul.mm-listview li a {
    color: #555 !important;
    display: block;
  }
}
hero_single {
  width: 100%;
  position: relative;
  overflow: hidden;
  text-align: center;
  margin: 0;
  color: #fff;
}
.hero_single .wrapper {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.8);
}
.hero_single.start_bg_zoom:before {
  animation: pop-in 6s 0.3s cubic-bezier(0, 0.5, 0, 1) forwards;
  content: "";
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}
.hero_single .wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.hero_single .wrapper h3 {
  color: #fff;
  font-size: 52px;
  font-size: 3.25rem;
  margin: 0;
  text-transform: uppercase;
  font-weight: 700;
}
@media (max-width: 767px) {
  .hero_single .wrapper h3 {
    font-size: 21px;
    font-size: 1.3125rem;
  }
}
@media (max-width: 575px) {
  .hero_single .wrapper h3 {
    font-size: 23px;
    font-size: 1.4375rem;
  }
}
.hero_single .wrapper p {
  font-weight: 300;
  margin: 10px 0 0 0;
  padding: 0 20%;
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.4;
}
.hero_single .wrapper p strong {
  font-weight: 600;
}
@media (max-width: 767px) {
  .hero_single .wrapper p {
    padding: 0;
    font-size: 18px;
    font-size: 1.125rem;
  }
}
.hero_single.short {
  height: 600px;
}
.hero_single.version_2 {
  height: 190px;
}
.hero_single.version_2:before {
  background: url(home_section_1.jpg) center center no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.hero_single.version_2 .wrapper {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.45);
}
.hero_single.version_2 .wrapper h3 {
  font-size: 42px;
  font-size: 2.625rem;
  text-shadow: 4px 4px 12px rgba(0, 0, 0, 0.3);
}
@media (max-width: 767px) {
  .hero_single.version_2 .wrapper h3 {
    font-size: 26px;
    font-size: 1.625rem;
    margin-top: 60px;
  }
}
@media (max-width: 575px) {
  .hero_single.version_2 .wrapper h3 {
    font-size: 22px;
    font-size: 1.375rem;
  }
}
.hero_single.version_2 .wrapper p {
  font-weight: 400;
  margin: 5px 0 20px 0;
  padding: 0;
  font-size: 21px;
  font-size: 1.3125rem;
  text-shadow: 4px 4px 12px rgba(0, 0, 0, 0.3);
}
.hero_single.version_2 .wrapper p strong {
  font-weight: 600;
}
@media (max-width: 767px) {
  .hero_single.version_2 .wrapper p {
    padding: 0;
    font-size: 14px;
    font-size: 0.875rem;
  }
}

#custom-search-input {
  padding: 0;
  width: 600px;
  margin: 20px auto 0;
  position: relative;
}
@media (max-width: 991px) {
  #custom-search-input {
    width: auto;
  }
}
#custom-search-input .search-query {
  width: 100%;
  height: 50px;
  padding-left: 20px;
  border: 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  font-size: 16px;
  font-size: 1rem;
  color: #333;
}
#custom-search-input .search-query:focus {
  outline: none;
}
@media (max-width: 575px) {
  #custom-search-input .search-query {
    padding-left: 15px;
  }
}
#custom-search-input input[type='submit'] {
  position: absolute;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  right: -1px;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  font-size: 0.875rem;
  top: 0;
  border: 0;
  padding: 0 25px;
  height: 50px;
  cursor: pointer;
  outline: none;
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  -ms-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
  background-color: #fc5b62;
}
#custom-search-input input[type='submit']:hover {
  background-color: #FFC107;
  color: #222;
}
@media (max-width: 575px) {
  #custom-search-input input[type='submit'] {
    text-indent: -999px;
    background: #fc5b62 url(home_section_1.jpg) no-repeat center center;
  }
}

.custom-search-input-2 {
  background-color: #fff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  margin-top: 15px;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.3);
}
@media (max-width: 991px) {
  .custom-search-input-2 {
    background: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
}
.custom-search-input-2 input {
  border: 0;
  height: 50px;
  padding-left: 15px;
  border-right: 1px solid #d2d8dd;
  font-weight: 500;
}
@media (max-width: 991px) {
  .custom-search-input-2 input {
    border: none;
  }
}
.custom-search-input-2 input:focus {
  box-shadow: none;
  border-right: 1px solid #d2d8dd;
}
@media (max-width: 991px) {
  .custom-search-input-2 input:focus {
    border-right: none;
  }
}
.custom-search-input-2 select {
  display: none;
}
.custom-search-input-2 .nice-select .current {
  font-weight: 500;
  color: #6f787f;
}
.custom-search-input-2 .form-group {
  margin: 0;
}
@media (max-width: 991px) {
  .custom-search-input-2 .form-group {
    margin-bottom: 5px;
  }
}
.custom-search-input-2 i {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  font-size: 18px;
  font-size: 1.125rem;
  position: absolute;
  background-color: #fff;
  line-height: 50px;
  top: 0;
  right: 1px;
  padding-right: 15px;
  display: block;
  width: 20px;
  box-sizing: content-box;
  height: 50px;
  z-index: 9;
  color: #999;
}
@media (max-width: 991px) {
  .custom-search-input-2 i {
    padding-right: 10px;
  }
}
.custom-search-input-2 input[type='submit'] {
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  font-size: 0.875rem;
  border: 0;
  padding: 0 25px;
  height: 50px;
  cursor: pointer;
  outline: none;
  width: 100%;
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  -ms-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
  background-color: #fc5b62;
  margin-right: -1px;
}
@media (max-width: 991px) {
  .custom-search-input-2 input[type='submit'] {
    margin: 20px 0 0 0;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
  }
}
.custom-search-input-2 input[type='submit']:hover {
  background-color: #FFC107;
  color: #222;
}
.custom-search-input-2.inner {
  margin-bottom: 30px;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
}
@media (max-width: 991px) {
  .custom-search-input-2.inner {
    margin: 0 0 20px 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
}
.custom-search-input-2.inner-2 {
  margin: 0 0 20px 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background: none;
}
.custom-search-input-2.inner-2 .form-group {
  margin-bottom: 10px;
}
.custom-search-input-2.inner-2 input {
  border: 1px solid #ededed;
}
.custom-search-input-2.inner-2 input[type='submit'] {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  margin-top: 10px;
}
.custom-search-input-2.inner-2 i {
  padding-right: 10px;
  line-height: 48px;
  height: 48px;
  top: 1px;
}
.custom-search-input-2.inner-2 .nice-select {
  border: 1px solid #ededed;
}
/*============================================================================================*/
/* 1.  SITE STRUCTURE and TYPOGRAPHY */
/*============================================================================================*/
/* MAIN NAVIGATION */
/* All styles for screen size over 992px*/
@media only screen and (min-width: 992px) {
  nav#menu.main-menu {
    display: block !important;
  }

  #mm-menu.main-menu {
    display: none !important;
  }

  header .btn_mobile {
    display: none !important;
  }

  /* Menu */
  .main-menu {
    position: relative;
    z-index: 9;
    width: auto;
    top: 12px;
    right: 15px;
    float: right;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    color: #fff;
  }
  .main-menu ul,
  .main-menu ul li {
    position: relative;
    display: inline-block;
    margin: 0;
    padding: 0;
  }
  .main-menu ul a,
  .main-menu ul li a {
    position: relative;
    margin: 0;
    padding: 0;
    display: block;
    padding: 5px 10px;
    white-space: nowrap;
  }

  /*First level styles */
  .main-menu > ul > li span > a {
    color: #fff;
    padding: 0 8px 15px 8px;
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: 500;
  }

  header.sticky .main-menu > ul > li span > a {
    color: #444;
  }
  header.sticky .main-menu > ul > li span:hover > a {
    opacity: 1;
    color: #fc5b62;
  }

  /* Submenu*/
  .main-menu ul ul {
    position: absolute;
    z-index: 1;
    visibility: hidden;
    left: 3px;
    top: 100%;
    margin: 0;
    display: block;
    padding: 0;
    background: #fff;
    min-width: 200px;
    -webkit-box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.175);
    -moz-box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.175);
    box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.175);
    transform: scale(0.4);
    transform-origin: 10% top;
    transition: 0.15s linear, 0.1s opacity cubic-bezier(0.39, 0.575, 0.565, 1), 0.15s transform cubic-bezier(0.1, 1.26, 0.83, 1);
    opacity: 0;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
  }
  .main-menu ul ul:before {
    bottom: 100%;
    left: 15%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: #fff;
    border-width: 7px;
    margin-left: -7px;
  }
  .main-menu ul ul li {
    display: block;
    height: auto;
    padding: 0;
  }
  .main-menu ul ul li a {
    font-size: 12px;
    font-size: 0.75rem;
    font-weight: 500;
    color: #555;
    border-bottom: 1px solid #ededed;
    display: block;
    padding: 15px 10px;
    line-height: 1;
  }

  .main-menu ul li:hover > ul {
    padding: 0;
    visibility: visible;
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
    -webkit-transition-duration: 0s, 0.2s, 0.2s;
    transition-duration: 0s, 0.2s, 0.2s;
  }

  .main-menu ul ul li:first-child a:hover {
    -webkit-border-radius: 3px 3px 0 0;
    -moz-border-radius: 3px 3px 0 0;
    -ms-border-radius: 3px 3px 0 0;
    border-radius: 3px 3px 0 0;
  }
  .main-menu ul ul li:last-child a {
    border-bottom: none;
  }
  .main-menu ul ul li:last-child a:hover {
    -webkit-border-radius: 0 0 3px 3px;
    -moz-border-radius: 0 0 3px 3px;
    -ms-border-radius: 0 0 3px 3px;
    border-radius: 0 0 3px 3px;
  }
  .main-menu ul ul li:hover > a {
    background-color: #f9f9f9;
    color: #fc5b62;
    padding-left: 18px;
  }
}
/* Submenu 2nd level right */
.main-menu ul ul.second_level_right {
  left: auto;
  right: 3px;
}
.main-menu ul ul.second_level_right:before {
  right: 15%;
  left: auto;
}

/* Submenu 3rd level */
.main-menu ul ul li span a {
  font-weight: 500 !important;
}
.main-menu ul ul li span a:hover {
  color: #fc5b62;
  padding-left: 18px;
  opacity: 1;
}
.main-menu ul ul li span a:after {
  font-family: 'ElegantIcons';
  content: "\35";
  float: right;
  font-size: 16px;
  font-size: 1rem;
  margin-top: -2px;
}

.main-menu ul ul ul {
  position: absolute;
  border-top: 0;
  z-index: 1;
  height: auto;
  left: 100%;
  top: 0;
  margin: 0;
  padding: 0;
  background: #fff;
  min-width: 190px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}
.main-menu ul ul ul.third_level_left {
  right: 100%;
  left: auto;
}
.main-menu ul ul ul li a {
  border-bottom: 1px solid #ededed !important;
}
.main-menu ul ul ul li:last-child a {
  border-bottom: none !important;
}

/* Arrows top 3rd level*/
.main-menu ul ul ul:before {
  border-width: 0;
  margin-left: 0;
}

/* All styles for screen size under 991px */
@media only screen and (max-width: 991px) {
  nav#menu {
    display: none !important;
  }

  .mm-menu {
    background: #fff;
  }

  .main-menu {
    top: 0;
    right: 0;
    float: none;
  }

  ul.mm-listview {
    line-height: 25px;
  }
  ul.mm-listview li a {
    color: #555 !important;
    display: block;
  }
}
#menu.fake_menu {
  display: none !important;
  visibility: hidden !important;
}

/*-------- Preloader --------*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  bottom: 0;
  background-color: #fff;
  z-index: 99999999999 !important;
}

[data-loader="circle-side"] {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  left: 50%;
  margin-left: -25px;
  margin-top: -25px;
  -webkit-animation: circle infinite .95s linear;
  -moz-animation: circle infinite .95s linear;
  -o-animation: circle infinite .95s linear;
  animation: circle infinite .95s linear;
  border: 2px solid #fc5b62;
  border-top-color: rgba(0, 0, 0, 0.2);
  border-right-color: rgba(0, 0, 0, 0.2);
  border-bottom-color: rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  border-radius: 100%;
}

@-webkit-keyframes circle {
  0% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes circle {
  0% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes circle {
  0% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes circle {
  0% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/*-------- 1.1 Typography --------*/
/* rem reference
10px = 0.625rem
12px = 0.75rem
14px = 0.875rem
16px = 1rem (base)
18px = 1.125rem
20px = 1.25rem
24px = 1.5rem
30px = 1.875rem
32px = 2rem
*/
html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background: #f8f8f8;
  font-size: 0.875rem;
  line-height: 1.6;
  font-family: "Poppins", Helvetica, sans-serif;
  color: #555;
  /* padding-top: 204px; */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #333;
}

p {
  margin-bottom: 30px;
}

strong {
  font-weight: 600;
}

label {
  font-weight: 500;
  margin-bottom: 3px;
}

hr {
  margin: 30px 0 30px 0;
  border-color: #ededed;
}
hr.large {
  border-color: #ededed;
  border-top-width: 4px;
  margin-top: 60px;
}
@media (max-width: 991px) {
  hr.large {
    margin-top: 45px;
  }
}
@media (max-width: 767px) {
  hr.large {
    margin-bottom: 45px;
  }
}

ul, ol {
  list-style: none;
  margin: 0 0 25px 0;
  padding: 0;
}

.main_title {
  text-align: center;
  margin-bottom: 45px;
  position: relative;
}

.main_title span {
  content: "";
  display: block;
  width: 60px;
  height: 3px;
  margin: auto;
  margin-bottom: 20px;
  opacity: 1;
  background-color: #999;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
}

.main_title.animated span {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.main_title_2 {
  text-align: center;
  margin-bottom: 45px;
  position: relative;
}
.main_title_2 span {
  width: 120px;
  height: 2px;
  background-color: #e1e1e1;
  display: block;
  margin: auto;
}
.main_title_2 span em {
  width: 60px;
  height: 2px;
  background-color: #edc773;
  display: block;
  margin: auto;
}
@media (max-width: 767px) {
  .main_title_2 {
    margin-bottom: 10px;
  }
}
.main_title_2 h2 {
  margin: 25px 0 0 0;
}
@media (max-width: 767px) {
  .main_title_2 h2 {
    font-size: 26px;
    font-size: 1.625rem;
  }
}
.main_title_2 h3 {
  margin: 25px 0 0 0;
}
@media (max-width: 767px) {
  .main_title_2 h3 {
    font-size: 24px;
    font-size: 1.5rem;
  }
}
.main_title_2 p {
  font-size: 24px;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.4;
}

.main_title_2 p.terms2 {
    font-size:x-small
}

@media (max-width: 767px) {
  .main_title_2 p {
    font-size: 18px;
    font-size: 1.125rem;
  }
}

.main_title_3 {
  margin-bottom: 25px;
  position: relative;
}
.main_title_3 span {
  width: 120px;
  height: 2px;
  background-color: #e1e1e1;
  display: block;
}
.main_title_3 span em {
  width: 60px;
  height: 2px;
  background-color: #fc5b62;
  display: block;
}
@media (max-width: 767px) {
  .main_title_3 {
    margin-bottom: 10px;
  }
}
.main_title_3 h2, .main_title_3 h3 {
  font-size: 23px;
  font-size: 1.4375rem;
  margin: 25px 0 0 0;
}
@media (max-width: 767px) {
  .main_title_3 h2, .main_title_3 h3 {
    font-size: 21px;
    font-size: 1.3125rem;
  }
}
.main_title_3 p {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 1.4;
}

/** Layes slider typography ab buttons **/
h3.slide_typo {
  white-space: nowrap;
  color: #fff !important;
  font-weight: normal;
  font-size: 64px;
  font-size: 4rem;
}

.slide_typo_2 {
  color: #fff;
  font-size: 28px;
  font-size: 1.75rem;
  white-space: nowrap;
  text-transform: uppercase;
}

.ls-l a.btn_1 {
  white-space: nowrap;
}
@media (max-width: 767px) {
  .ls-l a.btn_1 {
    font-size: 11px !important;
    padding: 7px 15px !important;
  }
}

/*General links color*/
a {
  color: #fc5b62;
  text-decoration: none;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  outline: none;
}
a:hover, a:focus {
  color: #111;
  text-decoration: none;
  outline: none;
}

/*-------- 1.2 Buttons --------*/
a.btn_1,
.btn_1 {
  border: none;
  color: #fff;
  background: #fc5b62;
  outline: none;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  padding: 15px 30px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  line-height: 1;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}
a.btn_1:hover,
.btn_1:hover {
  background: #0054a6;
  color: #fff;
}
a.btn_1.full-width,
.btn_1.full-width {
  display: block;
  width: 100%;
  text-align: center;
  margin-bottom: 5px;
}
a.btn_1.full-width.purchase,
.btn_1.full-width.purchase {
  background-color: #32a067;
}
a.btn_1.full-width.purchase:hover,
.btn_1.full-width.purchase:hover {
  background-color: #0054a6;
}
a.btn_1.full-width.wishlist,
.btn_1.full-width.wishlist {
  border-color: #555;
  color: #555;
}
a.btn_1.medium,
.btn_1.medium {
  font-size: 16px;
  font-size: 1rem;
  padding: 18px 40px;
}
a.btn_1.rounded,
.btn_1.rounded {
  -webkit-border-radius: 25px !important;
  -moz-border-radius: 25px !important;
  -ms-border-radius: 25px !important;
  border-radius: 25px !important;
}
a.btn_1.outline,
.btn_1.outline {
  border: 2px solid #fc5b62;
  color: #fc5b62;
  padding: 11px 40px;
  background: none;
}
a.btn_1.outline:hover,
.btn_1.outline:hover {
  background: #0054a6;
  color: #fff;
  border-color: #0054a6;
}
a.btn_1.outline:focus,
.btn_1.outline:focus {
  outline: none;
}

.closebt {
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  position: absolute;
  font-size: 24px;
  font-size: 1.5rem;
  top: 10px;
  right: 15px;
  color: #fff;
  opacity: 0.6;
  width: 30px;
  height: 30px;
}
.closebt:hover {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  opacity: 1;
}

.btn_home_align {
  text-align: right;
  margin-bottom: 25px;
}
@media (max-width: 767px) {
  .btn_home_align {
    text-align: center;
    margin-bottom: 25px;
  }
}

a.btn_map {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  padding: 8px 12px 8px 12px;
  line-height: 1;
  margin-bottom: 5px;
  display: inline-block;
  border: 1px solid rgba(0, 0, 0, 0.08);
  font-weight: 600;
  font-size: 12px;
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.5);
}
a.btn_map:hover {
  color: #fc5b62;
}
a.btn_map:before {
  font-family: 'ElegantIcons';
  content: "\e081";
  display: inline-block;
  margin-right: 5px;
}

a.btn_map_in,
.btn_map_in {
  border: none;
  color: #fff;
  background: #fc5b62;
  outline: none;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  padding: 15px 30px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
  display: inline-block;
  line-height: 1;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}
a.btn_map_in:hover,
.btn_map_in:hover {
  background: #0054a6;
  color: #fff;
}

/*-------- 1.3 Structure --------*/
/* Header */
header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  padding: 20px 30px;
  z-index: 99999999;
  border-bottom: 1px solid rgba(255, 255, 255, 0);
  background-color: blue;
}
@media (max-width: 767px) {
  header {
    padding: 15px 15px 5px 15px;
  }
}
header.header .logo_sticky {
  display: none;
}
header.header.sticky {
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border-bottom: 1px solid #ededed;
  background-color: #fff;
  padding: 15px 20px;
}
@media (max-width: 991px) {
  header.header.sticky {
    padding: 15px 15px 5px 15px;
  }
}
header.header.sticky .logo_normal {
  display: none;
}
header.header.sticky .logo_sticky {
  display: inline-block;
}

ul#top_menu {
  float: right;
  margin: 0 0 0 10px;
  padding: 0;
  font-size: 13px;
  font-size: 0.8125rem;
}
@media (max-width: 991px) {
  ul#top_menu {
    margin: -2px 0 0 10px;
  }
}
ul#top_menu li {
  float: left;
  margin: 0;
  line-height: 1;
  margin-right: 15px;
}
ul#top_menu li:last-child {
  margin-right: 0;
  position: relative;
  top: 0;
}
@media (max-width: 991px) {
  ul#top_menu li {
    margin: 0 0 0 10px;
  }
}
@media (max-width: 767px) {
  ul#top_menu li:first-child {
    display: none;
  }
}
ul#top_menu li a {
  color: #fff;
}
ul#top_menu li a:hover {
  color: #fff;
  opacity: 0.7;
}
ul#top_menu li a.login, ul#top_menu li a.wishlist_bt_top, ul#top_menu li a.cart-menu-btn {
  display: block;
  width: 22px;
  height: 23px;
  position: relative;
  top: 8px;
  -moz-transition: opacity 0.5s ease;
  -o-transition: opacity 0.5s ease;
  -webkit-transition: opacity 0.5s ease;
  -ms-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
}
@media (max-width: 991px) {
  ul#top_menu li a.login, ul#top_menu li a.wishlist_bt_top, ul#top_menu li a.cart-menu-btn {
    top: 2px;
  }
}
ul#top_menu li a.login:before, ul#top_menu li a.wishlist_bt_top:before, ul#top_menu li a.cart-menu-btn:before {
  font-family: 'Glyphter';
  font-size: 21px;
  font-size: 1.3125rem;
  color: #fff;
  text-indent: 0;
  position: absolute;
  left: 0;
  top: 0;
  font-weight: normal;
  line-height: 1;
}
.sticky ul#top_menu li a.login:before, .sticky ul#top_menu li a.wishlist_bt_top:before, .sticky ul#top_menu li a.cart-menu-btn:before {
  color: #444;
}
ul#top_menu li a.wishlist_bt_top {
  text-indent: -9999px;
}
ul#top_menu li a.wishlist_bt_top:before {
  content: '\0043';
}
ul#top_menu li a.login {
  text-indent: -9999px;
}
ul#top_menu li a.login:before {
  content: '\0042';
}
ul#top_menu li a.cart-menu-btn {
  top: 6px;
}
@media (max-width: 991px) {
  ul#top_menu li a.cart-menu-btn {
    top: 0;
  }
}
ul#top_menu li a.cart-menu-btn:before {
  content: '\0041';
}
ul#top_menu li a.cart-menu-btn strong {
  font-size: 11px;
  font-size: 0.6875rem;
  width: 15px;
  height: 15px;
  display: block;
  background-color: #333;
  color: #fff;
  position: relative;
  bottom: -15px;
  right: -10px;
  text-align: center;
  line-height: 17px !important;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  margin: 0;
  padding: 0;
}
ul#top_menu li a.logged {
  display: block;
  width: 25px;
  height: 25px;
  position: relative;
  overflow: hidden;
  top: 6px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  -moz-transition: opacity 0.5s ease;
  -o-transition: opacity 0.5s ease;
  -webkit-transition: opacity 0.5s ease;
  -ms-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
}
ul#top_menu li a.logged img {
  width: 26px;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
@media (max-width: 991px) {
  ul#top_menu li a.logged {
    top: 0;
  }
}


#logo {
  float: left;
}
@media (max-width: 991px) {
  #logo {
    float: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
  }
  #logo img {
    width: auto;
    height: 30px;
    margin: 12px 0 0 0;
  }
}


/* Checkbox style */
.container_check {
  display: block;
  position: relative;
  padding-left: 30px;
  line-height: 1.8;
  margin-bottom: 5px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.container_check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.container_check input:checked ~ .checkmark {
  background-color: #0054a6;
  border: 1px solid transparent;
}
.container_check .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #d2d8dd;
  background-color: #fff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.container_check .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.container_check input:checked ~ .checkmark:after {
  display: block;
}

.vaguesearch {
  font-weight: bold;
  color:#edc773 !important; 
}
switch-field {
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.08);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}
.switch-field input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}
.switch-field input:checked + label {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  color: #fff;
}
.switch-field label {
  float: left;
  display: inline-block;
  min-width: 70px;
  color: rgba(255,255,255,.5);
  font-weight: 600;
  font-size: 12px;
  font-size: 0.75rem;
  text-align: center;
  text-shadow: none;
  padding: 10px 12px 8px 12px;
  line-height: 1;
  border-right: 1px solid rgba(0, 0, 0, 0.08);
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  margin: 0;
}
@media (max-width: 575px) {
  .switch-field label {
    min-width: inherit;
  }
}
.switch-field label:first-of-type {
  border-left: 0;
}
.switch-field label:last-of-type {
  border-right: 0;
}
.switch-field label:hover {
  cursor: pointer;
  color: #edc773
}

.layout_view {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  /* border-radius: 3px; */
  /* border: 1px solid rgba(0, 0, 0, 0.08); */
  padding: 4px 8px 3px 8px;
  color:#fff;
}
.layout_view a {
  color: rgba(0, 0, 0, 0.5);
}
.layout_view a.active {
  color: rgba(0, 0, 0, 0.8);
}
.layout_view a:hover {
  color: #fc5b62;
}

/*-------- 2.3 Listing --------*/
.filters_listing {
  /* padding: 5px 0 5px 0; */
  background:#121921;
  /* z-index: 999999 !important; */
display: flex;
  width: 100%;
}
.filters_listing ul {
  margin: 0 15px;
}
@media (max-width: 991px) {
  .filters_listing ul {
    margin: 0;
  }
}
.filters_listing ul li {
  margin-right: 10px;
}
.filters_listing ul li:first-child {
  float: left;
}
.filters_listing ul li:nth-child(2) {
  float: right;
  margin-right: 0;
}
.filters_listing ul li:nth-child(3) {
  float: right;
}

footer {
  background-color: #121921;
  color: #fbfbfb;
  color: rgba(255, 255, 255, 0.7);
}
footer h5 {
  color: #fff;
  margin: 25px 0;
  font-size: 18px;
  font-size: 1.125rem;
}
@media (max-width: 575px) {
  footer h5 {
    margin: 25px 0 10px 0;
  }
}
footer ul li {
  margin-bottom: 5px;
}
footer ul li a {
  display: inline-block;
  position: relative;
  color: #edc773;
  opacity: 0.7;
}
footer ul li a:hover {
  color: #fff;
  opacity: 1;
}
footer ul li a i {
  margin-right: 10px;
  color: #fff;
}

footer ul.contacts li a i {
  margin-right: 10px;
}
footer hr {
  opacity: 0.1;
}
footer #copy {
  text-align: right;
  font-size: 13px;
  font-size: 0.8125rem;
  opacity: 0.7;
}
@media (max-width: 767px) {
  footer #copy {
    text-align: left;
    margin: 5px 0 20px 0;
  }
}

/* Newsletter footer*/
#newsletter .form-group {
  position: relative;
}
#newsletter .loader {
  position: absolute;
  right: -20px;
  top: 11px;
  color: #fff;
  font-size: 12px;
  font-size: 0.75rem;
}
#newsletter h6 {
  color: #fff;
  margin: 15px 0 15px 0;
}
#newsletter .form-group {
  position: relative;
}
#newsletter input[type='email'] {
  border: 0;
  height: 40px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  padding-left: 15px;
  font-size: 14px;
  font-size: 0.875rem;
  background-color: #f3f3f3;
  background-color: rgba(255, 255, 255, 0.06);
  color: #fff;
}
#newsletter input[type='email']:focus {
  border: 0;
  box-shadow: none;
}
#newsletter input[type='submit'] {
  position: absolute;
  right: 0;
  color: black;
  font-size: 13px;
  font-size: 0.8125rem;
  font-weight: 600;
  top: 0;
  border: 0;
  padding: 0 12px;
  height: 40px;
  cursor: pointer;
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  -ms-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
  background-color: #edc773;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  outline: none;
}
#newsletter input[type='submit']:hover {
  background-color: #FFC107;
  color: #222;
}

.follow_us {
  margin-top: 15px;
  animation-delay: 1.1s;
  -webkit-animation-delay: 1.1s;
  -moz-animation-delay: 1.1s;
}
.follow_us h5 {
  color: #fff;
}
.follow_us ul li {
  display: inline-block;
  margin-right: 10px;
  font-size: 20px;
  font-size: 1.25rem;
}
.follow_us ul li:first-child {
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 2px;
  font-size: 13px;
  font-size: 0.8125rem;
}
.follow_us ul li a {
  color: #edc773;
  opacity: 0.7;
}
.follow_us ul li a:hover {
  opacity: 1;
}

ul#footer-selector {
  margin: 0 0 0 0;
  padding: 0;
  list-style: none;
}
ul#footer-selector li {
  float: left;
  margin-right: 10px;
}
@media (max-width: 575px) {
  ul#footer-selector li:last-child {
    margin-top: 5px;
  }
}

/* Addtional links Footer */
ul#additional_links {
  margin: 0;
  padding: 8px 0 0 0;
  color: #edc773;
  font-size: 13px;
  font-size: 0.8125rem;
  float: right;
}
@media (max-width: 991px) {
  ul#additional_links {
    float: none;
    margin-top: 10px;
  }
}
ul#additional_links li {
  display: inline-block;
  margin-right: 15px;
}
ul#additional_links li:first-child {
  margin-right: 20px;
}
ul#additional_links li:last-child:after {
  content: "";
}
ul#additional_links li span {
  color: #fff;
  opacity: 0.5;
}
ul#additional_links li a {
  color: #fff;
  opacity: 0.5;
}
ul#additional_links li a:hover {
  opacity: 1;
}
ul#additional_links li:after {
  content: "|";
  font-weight: 300;
  position: relative;
  left: 10px;
}

.styled-select {
  width: 100%;
  overflow: hidden;
  height: 30px;
  line-height: 30px;
  border: none;
  background-color: #f3f3f3;
  background-color: rgba(255, 255, 255, 0.05);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  padding-right: 35px;
  position: relative;
}
.styled-select#lang-selector:after {
  font-family: 'ElegantIcons';
  content: "\e0e2";
  color: #fff;
  position: absolute;
  right: 10px;
  top: 0;
}
.styled-select#currency-selector:after {
  font-family: 'ElegantIcons';
  content: "\33";
  color: #fff;
  position: absolute;
  right: 10px;
  top: 0;
}
.styled-select select {
  background: transparent;
  width: 110%;
  padding-left: 10px;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  height: 30px;
  color: #ccc;
  font-size: 12px;
  font-size: 0.75rem;
  margin: 0;
  font-weight: 500;
  -moz-appearance: window;
  -webkit-appearance: none;
  cursor: pointer;
  outline: none;
}
.styled-select select:focus {
  color: #fff;
  outline: none;
  box-shadow: none;
}
.styled-select select::-ms-expand {
  display: none;
}

.styled-select#lang-selector select option {
  color: #555;
}

/* Wow on scroll event */
.wow {
  visibility: hidden;
}


.margin_60_35 {
  padding-top: 60px;
  padding-bottom: 35px;
}
@media (max-width: 575px) {
  .margin_60_35 {
    padding-top: 30px;
    padding-bottom: 5px;
  }
}

.margin_80 {
  padding-top: 80px;
  padding-bottom: 80px;
}
@media (max-width: 991px) {
  .margin_80 {
    padding-bottom: 60px;
    padding-top: 60px;
  }
}
@media (max-width: 575px) {
  .margin_80 {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}


.margin_80_0 {
  padding-top: 80px;
}
@media (max-width: 991px) {
  .margin_80_0 {
    padding-top: 60px;
  }
}
@media (max-width: 575px) {
  .margin_80_0 {
    padding-top: 45px;
  }
}
.margin_80_55 {
  padding-top: 110px;
  padding-bottom: 55px;
}
@media (max-width: 991px) {
  .margin_80_55 {
    padding-top: 90px;
    padding-bottom: 35px;
  }
}
@media (max-width: 575px) {
  .margin_80_55 {
    padding-top: 75px;
    padding-bottom: 15px;
  }
}


.map_contact {
  width: 100%;
  height: 465px;
}
@media (max-width: 991px) {
  .map_contact {
    height: 350px;
    margin-bottom: 45px;
  }
}

.error_message {
  font-weight: 500;
  color: #e3464c;
}

/*Carousel home page*/
#reccomended {
  margin-top: 40px;
}
@media (max-width: 767px) {
  #reccomended {
    margin-top: 0;
  }
}
#reccomended .item {
  margin: 0 15px;
}

#reccomended .owl-item {
  opacity: 0.5;
  transform: scale(0.85);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(0.85, 0.85);
  transition: all 0.3s ease-in-out 0s;
  overflow: hidden;
}

#reccomended .owl-item.active.center {
  opacity: 1;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(1, 1);
  transform: scale(1);
}

#reccomended .owl-item.active.center .item .title h4,
#reccomended .owl-item.active.center .item .views {
  opacity: 1;
}

.owl-theme .owl-dots {
  margin-top: 10px !important;
  margin-bottom: 25px;
}
@media (max-width: 767px) {
  .owl-theme .owl-dots {
    margin-top: 0 !important;
    margin-bottom: 20px;
  }
}

.owl-theme .owl-dots .owl-dot {
  outline: none;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #fc5b62 !important;
}

.owl-theme .owl-dots .owl-dot span {
  width: 13px !important;
  height: 13px !important;
}

html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background: #f8f8f8;
  font-size: 0.875rem;
  line-height: 1.6;
  font-family: "Poppins", Helvetica, sans-serif;
  color: #555;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #333;
}

p {
  margin-bottom: 30px;
  padding-right: 75px;
}

strong {
  font-weight: 600;
}

label {
  font-weight: 500;
  margin-bottom: 3px;
}

hr {
  margin: 30px 0 30px 0;
  border-color: #ededed;
}
hr.large {
  border-color: #ededed;
  border-top-width: 4px;
  margin-top: 60px;
}
@media (max-width: 991px) {
  hr.large {
    margin-top: 45px;
  }
}
@media (max-width: 767px) {
  hr.large {
    margin-bottom: 45px;
  }
}

ul, ol {
  list-style: none;
  margin: 0 0 25px 0;
  padding: 0;
}


/*General links color*/
a {
  color: #fc5b62;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  outline: none;
}
a:hover, a:focus {
  color: #111;
  text-decoration: none;
  outline: none;
}

.cardLink {
  color: #edc773;
  text-decoration: none;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  outline: none;
}

.cardLink:hover, .cardLink:focus {
  color: #111;
  text-decoration: none;
  outline: none;
}

.wrapper-grid {
  padding:10px 20px;
}

.box_grid {
  background-color: #fff;
  display: block;
  position: relative;
  margin-bottom: 30px;
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
}
.box_grid .price {
  /* display: inline-block; */
  font-weight: 500;
  color: #999;
  float: left;
  /* padding-top: 50px;
  white-space: pre; */
}
.box_grid .time {
  /* display: inline-block; */
  float:right;
  /* padding-top: 50px;
  white-space: pre; */
}
.box_grid .price strong {
  color: #32a067;
}
.box_grid a.wish_bt {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 1;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 9px 10px;
  display: inline-block;
  color: #fff;
  line-height: 1;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}
.box_grid a.wish_bt:after {
  font-family: 'ElegantIcons';
  content: "\e030";
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.box_grid a.wish_bt.liked:after {
  content: "\e089";
  color: #fc5b62;
}
.box_grid a.wish_bt:hover.liked:after {
  color: #fc5b62;
}
.box_grid a.wish_bt:hover:after {
  content: "\e089";
  color: #fff;
}
.box_grid figure {
  margin-bottom: 0;
  overflow: hidden;
  position: relative;
  height: 413px;
}
.box_grid figure small {
  position: absolute;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
  left: 20px;
  top: 22px;
  text-transform: uppercase;
  color: #ccc;
  font-weight: 600;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  padding: 7px 10px 4px 10px;
  line-height: 1;
}
.box_grid figure small.auction {
  position: absolute;
  background-color: black;
  background-color: green;
  left: 20px;
  top: 22px;
  text-transform: uppercase;
  color: #ccc;
  font-weight: 600;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  padding: 7px 10px 4px 10px;
  line-height: 1;
}
.box_grid figure .read_more {
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -12px;
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  -ms-transform: translateY(10px);
  -o-transform: translateY(10px);
  transform: translateY(10px);
  text-align: center;
  opacity: 0;
  visibility: hidden;
  width: 100%;
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
  z-index: 2;
}
.box_grid figure .read_more span {
  background-color: #fcfcfc;
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  border-radius: 20px;
  display: inline-block;
  color: #222;
  font-size: 12px;
  font-size: 0.75rem;
  padding: 5px 10px;
}
.box_grid figure:hover .read_more {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}
.box_grid figure a img {
  width: unset !important;
  position: absolute;
  left: 50%;
  top: 55%;
  -webkit-transform: translate(-50%, -50%) scale(1.0);
  -moz-transform: translate(-50%, -50%) scale(1.0);
  -ms-transform: translate(-50%, -50%) scale(1.0);
  -o-transform: translate(-50%, -50%) scale(1.0);
  transform: translate(-50%, -50%) scale(1.0);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  /* width: 100%; */
  max-height: 100%;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}
.box_grid figure a:hover img {
  -webkit-transform: translate(-50%, -50%) scale(1);
  -moz-transform: translate(-50%, -50%) scale(1);
  -ms-transform: translate(-50%, -50%) scale(1);
  -o-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
}
.box_grid .wrapper {
  padding: 25px;
}
.box_grid .wrapper h3 {
  font-size: 20px;
  font-size: 1.25rem;
  margin-top: 0;
}
.box_grid ul {
  padding: 20px 15px;
  border-top: 1px solid #ededed;
}
.box_grid ul li {
  display: inline-block;
  margin-right: 15px;
}
.box_grid ul li .score {
  margin-top: -10px;
}
.box_grid ul li:last-child {
  margin-right: 0;
  float: right;
}

.cat_star i {
  margin-right: 2px;
  color: #FFC107;
}

.rating {
  color: #ccc;
}
.rating .voted {
  color: #FFC107;
}
.rating i {
  margin-right: 2px;
}

.box_list {
  background-color: #fff;
  display: block;
  position: relative;
  margin: 0 15px 30px 15px;
  min-height: 310px;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
}
.box_list a.wish_bt {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 1;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 9px 10px;
  display: inline-block;
  color: #555;
  line-height: 1;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}
.box_list a.wish_bt:after {
  font-family: 'ElegantIcons';
  content: "\e030";
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.box_list a.wish_bt.liked:after {
  content: "\e089";
  color: #fc5b62;
}
.box_list a.wish_bt:hover.liked:after {
  color: #fc5b62;
}
.box_list a.wish_bt:hover:after {
  content: "\e089";
  color: #999;
}
.box_list figure {
  margin-bottom: 0;
  overflow: hidden;
  position: relative;
  min-height: 310px;
}
@media (max-width: 991px) {
  .box_list figure {
    min-height: 220px;
    max-height: 220px;
    height: 220px;
  }
}
.box_list figure small {
  position: absolute;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
  right: 20px;
  top: 20px;
  text-transform: uppercase;
  color: #ccc;
  font-weight: 600;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  padding: 7px 10px 4px 10px;
  line-height: 1;
  z-index: 99;
}
@media (max-width: 991px) {
  .box_list figure small {
    right: 15px;
    top: 15px;
  }
}
@media (max-width: 991px) {
  .box_list figure {
    min-height: 220px;
    max-height: 220px;
    height: 220px;
  }
}
.box_list figure .read_more {
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -12px;
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  -ms-transform: translateY(10px);
  -o-transform: translateY(10px);
  transform: translateY(10px);
  text-align: center;
  opacity: 0;
  visibility: hidden;
  width: 100%;
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
  z-index: 2;
}
.box_list figure .read_more span {
  background-color: #fcfcfc;
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  border-radius: 20px;
  display: inline-block;
  color: #222;
  font-size: 12px;
  font-size: 0.75rem;
  padding: 5px 10px;
}
.box_list figure:hover .read_more {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}
.box_list figure a img {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) scale(1.1);
  -moz-transform: translate(-50%, -50%) scale(1.1);
  -ms-transform: translate(-50%, -50%) scale(1.1);
  -o-transform: translate(-50%, -50%) scale(1.1);
  transform: translate(-50%, -50%) scale(1.1);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  width: 100%;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  height: 320px;
  width: auto;
  max-width: none;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 991px) {
  .box_list figure a img {
    max-width: inherit;
    height: 460px;
    width: auto;
  }
}
@media (max-width: 767px) {
  .box_list figure a img {
    height: 350px;
  }
}
.box_list figure a:hover img {
  -webkit-transform: translate(-50%, -50%) scale(1);
  -moz-transform: translate(-50%, -50%) scale(1);
  -ms-transform: translate(-50%, -50%) scale(1);
  -o-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
}
.box_list .wrapper {
  padding: 30px 30px 20px 30px;
  min-height: 245px;
}
@media (max-width: 991px) {
  .box_list .wrapper {
    min-height: inherit;
  }
}
.box_list .wrapper h3 {
  font-size: 20px;
  font-size: 1.25rem;
  margin-top: 0;
}
.box_list .wrapper .price {
  display: inline-block;
  font-weight: 500;
  color: #999;
}
.box_list .wrapper .price strong {
  color: #32a067;
}
.box_list small {
  text-transform: uppercase;
  color: #999;
  font-weight: 600;
}
.box_list ul {
  padding: 20px 15px 20px 30px;
  border-top: 1px solid #ededed;
  margin-bottom: 0;
}
.box_list ul li {
  display: inline-block;
  margin-right: 15px;
}
.box_list ul li .score {
  margin-top: -10px;
}
.box_list ul li:last-child {
  margin-right: 0;
  float: right;
}
@media (min-width: 1400px) {
  .container-custom {
    max-width: 1600px !important;
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
}
.opacity-mask {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}

.form-control {
  padding: 10px;
  height: 42px;
  font-size: 14px;
  font-size: 0.875rem;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid #d2d8dd;
}

.form-group {
  position: relative;
}

.bg_color_1 {
  background-color: #fff;
}

.box_1 {
  background-color: #121921;
  color: #fff;
  padding: 45px;
  opacity: 0;
}
.box_1 p {
  opacity: 0.8;
}
.box_1 h3 {
  color: #fff;
}

/*-------- 2.2 About --------*/
a.box_feat {
  text-align: center;
  background: #fff;
  padding: 40px 30px 30px 30px;
  display: block;
  -moz-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  -webkit-transition: all 500ms ease-in-out;
  -ms-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
  -webkit-box-shadow: 0px 3px 0px 0px #f0f2f4;
  -moz-box-shadow: 0px 3px 0px 0px #f0f2f4;
  box-shadow: 0px 3px 0px 0px #f0f2f4;
  color: #555;
  margin-bottom: 30px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  overflow: hidden;
}
@media (max-width: 767px) {
  a.box_feat {
    padding: 20px 20px 0 20px;
  }
}
a.box_feat:hover {
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  -o-transform: translateY(-10px);
  transform: translateY(-10px);
  -webkit-box-shadow: 0px 3px 0px 0px #ebedef;
  -moz-box-shadow: 0px 3px 0px 0px #ebedef;
  box-shadow: 0px 3px 0px 0px #ebedef;
}
a.box_feat i {
  font-size: 70px;
  font-size: 4.375rem;
  color: #32a067;
  margin-bottom: 10px;
}
a.box_feat h3 {
  font-size: 18px;
  font-size: 1.125rem;
}

.block-reveal {
  height: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.block-horizzontal, .block-vertical {
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 10;
}
.help_pages_background {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.45);
}
/* 
.faq {
  padding: 200px 230px;
  margin: 0;
  height:750px;
  position: relative;
}

@media (max-width: 767px) {
  .faq {
    padding: 150px 180px;
    height:550px;
  }
}
@media (max-width: 575px) {
  .faq {
    padding: 50px 8 0px;
    height:350px;
  }
} */


.contact_info {
  background-color: #121921;
  color: #fff;
  padding: 30px 0;
  text-align: center;
}
.contact_info ul {
  margin: 0;
  padding: 0;
}
.contact_info ul li {
  display: inline-block;
  width: 33.33%;
  padding: 0 20px;
}
.contact_info ul li span {
  opacity: 0.7;
}
.contact_info ul li h4 {
  color: #fff;
  font-size: 18px;
  font-size: 1.125rem;
  color: #edc773;
}
.contact_info ul li i {
  color: #fff;
  font-size: 46px;
  font-size: 2.875rem;
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  .contact_info ul li {
    width: 100%;
    float: none;
    margin-bottom: 35px;
    padding: 0;
  }
  .contact_info ul li:last-child {
    margin-bottom: 5px;
  }
}

.hero_in {
  width: 100%;
  height: 450px;
  position: relative;
  overflow: hidden;
  text-align: center;
  color: #fff;
}
@media (max-width: 767px) {
  .hero_in {
    height: 350px;
  }
}


.hero_in.contacts:before {
  background: url(hero_in_bg_3.jpg) center center no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.hero_in.contacts .wrapper {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
}
.hero_in:before {
  animation: pop-in 5s 0.3s cubic-bezier(0, 0.5, 0, 1) forwards;
  content: "";
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}
.hero_in .wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.hero_in .wrapper h1 {
  color: #fff;
  font-size: 42px;
  font-size: 2.625rem;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 30px;
}
@media (max-width: 767px) {
  .hero_in .wrapper h1 {
    font-size: 32px;
    font-size: 2rem;
  }
}
.hero_in .wrapper h1 span {
  width: 80px;
  height: 4px;
  margin: auto;
  background-color: #FFC107;
  display: block;
  margin: auto;
  margin-bottom: 20px;
  border-radius: 10px;
  opacity: 1;
}
.hero_in .wrapper p {
  font-weight: 300;
  margin-bottom: 0;
  font-size: 24px;
  font-size: 1.5rem;
}
.hero_in .wrapper p strong {
  font-weight: 600;
}
.hero_in a.btn_photos {
  position: absolute;
  left: 20px;
  bottom: 20px;
  background-color: #fff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
  line-height: 1;
  padding: 10px 15px;
  color: #444;
  font-weight: 500;
}
.hero_in a.btn_photos:hover {
  color: #fc5b62;
}

.smallBar {
  padding-top: 5px;
  padding-bottom: 5px;
}

.footer {
  display: block;
}

.fixed-top-2 {
  margin-top: 56px;
}

.fixed-top-3 {
  margin-top: 246px;
}

.navbar-header {
  background-color: rgba(237,199,115,255) !important;
}

/* 121921 */
.bg-dark {
  background-color: #121921 !important;
}

.navbar-dark .navbar-nav .nav-link {
  color:#edc773;
}
.navbar-dark .navbar-brand {
  color:#edc773;
}
.btn-outline-success {
color: #edc773;
border-color: #edc773;
}

.text-gold-inline {
  color: #edc773;
  display: inline;
}

.text-gold { 
  color: #edc773;
}
.owl-theme .owl-dots .owl-dot.active span {
  background: #edc773 !important;
}

 .owl-dot span:hover {
  color:#edc773;
}


.caro_header {
  /* height: 220px; */
  width: 100%;
  position: relative;
  overflow: hidden;
  text-align: center;
  /* margin-top: 50px; */
  color: #fff;
}
/* .caro_header:before {
  background: url(home_section_1.jpg) center center no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
} */
.caro_header .wrapper {
  /* background-color: #121921; */
}
.caro_header .wrapper h3 {
  /* font-size: 26px;
  font-size: 1.625rem; */
  /* color:#edc773; */
  font-size: 32px;
  line-height: 40px;
}
.caro_header .wrapper p {
  /* color: #edc773; */
}
@media (max-width: 767px) {
  .caro_header.wrapper h3 {
    font-size: 22px;
    font-size: 1.375rem;
    margin-top: 60px;
  }
}
@media (max-width: 575px) {
  .caro_header .wrapper h3 {
    font-size: 22px;
    font-size: 1.375rem;
  }
}
.caro_header .wrapper p {
  font-weight: 400;
  margin: 5px 0 20px 0;
  padding: 0;
  font-size: 21px;
  font-size: 1.3125rem;
  color: #edc773;
}
.caro_header.version_2 .wrapper p strong {
  font-weight: 600;
}
@media (max-width: 767px) {
  .caro_header .wrapper p {
    padding: 0;
    font-size: 14px;
    font-size: 0.875rem;
  }
}

#newsletter a {
  display: inline-block;
  position: relative;
  color: #edc773;
  opacity: 0.7;
}
#newsletter a:hover {
  color: #fff;
  opacity: 1;
}

.btn-outline-success:hover {
  color: #edc773;
  background-color: #121921;
  border-color: #121921;
  cursor:pointer;
}
.btn-outline-success:not(:disabled):not(.disabled):active {
  color: #edc773;
  background-color: #121921;
  border-color: #121921;
  cursor:pointer;
}

.btn-warning {
  color: #121921;
  background-color: #edc773;
  border-color: #edc773;
  cursor:pointer;
}
.btn-warning:hover {
  color: #121921;
  cursor:pointer;
  /* background-color: #fff;
   */
  background-color:#fff;
  border-color: #edc773;
}



.owl-item {
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(1.0, 1.0);
  transition: none !important;
}

.noresults {
  padding: 250px;
  text-align: center;
}

.noneResults {
  margin: auto;
}
.goldText {
  display: inline-block;
  color: #edc773;
}
.wrapperCard {
  padding: 25px;
}
/*related to sidebar*/
.wrapperPlaceholder {
  display: flex;
      width: 100%;
      align-items: stretch;
      perspective: 1500px;
  }
  /* Shrinking the sidebar from 250px to 80px and center aligining its content*/
  #sidebar.active {
    min-width: 240px;
    max-width: 240px;
    text-align: center;
  }
  
  .list-unstyled  {
    padding-left: 0;
    list-style: none;
  }
  
  nav#sidebar {
    background-color: #121921;
    min-width: 80px;
    max-width: 80px;
    text-align: center;
  }
  #sidebar .sidebar-header  {
    color: #edc773;
    background-color: #121921;
  }
  .rs-sidenav-body {
    color: #edc773;
    background-color: #121921;
  }
  .rs-button-subtle {
    color: #edc773 !important;
    background-color: #121921;
  }
  .rs-sidenav-default {
    background-color: #121921;
  }
  .rs-sidenav  {
    background-color: #121921;
  }
  .rs-dropdown-toggle {
    background-color: #121921  !important;
  }
  .rs-sidenav-default .rs-dropdown.rs-dropdown-open .rs-dropdown-toggle {
    background-color: #121921 !important;
  }
  div.rs-dropdown {
    background-color: #121921;
  }
  

  .rs-sidenav-default .rs-sidenav-item:hover,
.rs-sidenav-default .rs-dropdown .rs-dropdown-toggle:hover,
.rs-sidenav-default .rs-dropdown.rs-dropdown-open .rs-dropdown-toggle {
  color: white !important;
}

.rs-sidenav-default .rs-dropdown-item.rs-dropdown-item-active, .rs-sidenav-default .rs-dropdown.rs-dropdown-selected-within .rs-dropdown-toggle-icon, .rs-sidenav-default .rs-sidenav-item.rs-sidenav-item-active {
  color: white !important;
}
  #sidebar .sidebar-header  h3 {
    color: #edc773;
  }

   .wrapper > .rs-picker-default {
    background-color: #121921 !important;
   }
  .rs-picker-default .rs-btn, .rs-picker-default .rs-picker-toggle, .rs-picker-input .rs-btn, .rs-picker-input .rs-picker-toggle {
    background-color: #121921 !important;
  }
  .rs-picker-menu {
    background-color: #121921 !important;
    color: #edc773;
  }
  /* Toggling the sidebar header content, hide the big heading [h3] and showing the small heading [strong] and vice versa*/
  #sidebar .sidebar-header strong {
    display: block;
    font-size: 18px;
    line-height: 16px;
    color: #edc773;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 17px;
    font-weight: 400;
  }
  #sidebar.active .sidebar-header h3 {
    display: none;
  }
  #sidebar.active .sidebar-header strong {
    display: block;
  }
  
  #sidebar ul li a {
    text-align: left;
  }
  
  #sidebar ul li {
    display :none;
  }
  
  #sidebar ul li.active {
    display : block;
      font-size: 18px;
        line-height: 16px;
        color: #edc773;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-left: 17px;
        font-weight: 400;
  }
  
  
  #sidebar.active ul li a {
    padding: 20px 10px;
    text-align: center;
    font-size: 0.85em;
  }
  
  #sidebar.active ul li a i {
    margin-right:  0;
    display: block;
    font-size: 1.8em;
    margin-bottom: 5px;
  }
  
  /* Same dropdown links padding*/
  #sidebar.active ul ul a {
    padding: 10px !important;
  }
  
  /* Changing the arrow position to bottom center position, 
   translateX(50%) works with right: 50% 
   to accurately  center the arrow */
  #sidebar.active .dropdown-toggle::after {
    top: auto;
    bottom: 10px;
    right: 50%;
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
    transform: translateX(50%);
  }
  @media (max-width: 768px) {
    /* 80px and its content aligned to centre. Pushing it off the screen with the
       negative left margin
    */
    #sidebar.active {
        min-width: 80px;
        max-width: 80px;
        text-align: center;
        margin-left: -80px !important;
    }
  
  
    /* Reappearing the sidebar on toggle button click */
    #sidebar {
        margin-left: 0; 
    }
  
  
    /* Toggling the sidebar header content, 
       hide the big heading [h3] and showing the small heading [strong] and vice versa
    */
    #sidebar .sidebar-header strong {
        display: none;
    }
    #sidebar.active .sidebar-header h3 {
        display: none;
    }
    #sidebar.active .sidebar-header strong {
        display: block;
    }
  
    /* Downsize the navigation links font size */
    #sidebar.active ul li a {
        padding: 20px 10px;
        font-size: 0.85em;
    }
  
    #sidebar.active ul li a i {
        margin-right:  0;
        display: block;
        font-size: 1.8em;
        margin-bottom: 5px;
    }
  
    /* Adjust the dropdown links padding*/
    #sidebar.active ul ul a {
        padding: 10px !important;
    }
  
    /* Changing the arrow position to bottom center position, 
      translateX(50%) works with right: 50% 
      to accurately  center the arrow */
    .dropdown-toggle::after {
        top: auto;
        bottom: 10px;
        right: 50%;
        -webkit-transform: translateX(50%);
        -ms-transform: translateX(50%);
        transform: translateX(50%);
    }
  }

 .break {
    flex-basis: 100%;
    height: 0;
  }

  .list-unstyled {
    padding-left: 0;
    list-style: none;
}

.stickymetrics {
  position: sticky;
  top:128px;
  z-index: 900;
  
}
.stickySubHeader {
  position:sticky;
  top:75px;
  z-index: 900;
}

@media (min-width: 768px) { 
  .stickymetrics {
    position: sticky;
    top:105px;
    z-index: 900;
    
  }
  .stickySubHeader {
    position: sticky;
    top:80px;
    z-index: 900;
  }
 }

 .rs-dropdown-item {
   margin-left: 15px !important;
 }

 .filterExplainTitle {
    font-weight: bold;
    color:white;
 }

 .how-section1{
  margin-top:-15%;
  padding: 10%;
}
.how-section1 h4{
  color: #edc773;
  font-weight: bold;
  font-size: 30px;
}
.how-section1 .subheading{
  color: black;
  font-size: 20px;
}
.how-section1 .row
{
  margin-top: 10%;
}
.how-img 
{
  text-align: center;
}
.how-img img{
  width: 40%;
}
.savedsearch {
  font-size: x-small;
  color: white;
}
p.savedsearch {
  margin-top:0;
  margin-bottom:0;
}
p.savedsearch + p.savedsearch {
  margin-top:0;
  margin-bottom:0;
}
.savedsearchlink {
  text-decoration: none;
  color: #edc773
}
.savedsearchlink:visited {
  text-decoration: none;
  color: #edc773
}
.savedsearchlink:hover {
  text-decoration: none;
  color: rgba(255,255,255,.75)
}
.savedsearchlink:active {
  text-decoration: none;
}

.lowSupply {
  color: red;
  font-weight: 600;
}
.tenlist {
  padding-left: 0pt;
  list-style: circle;
}

.author_list {
  column-count: 4;
  grid-column-gap: 20px;
  column-gap: 20px;
}

.author_list_pp {
  margin-left: 0px;
  position: absolute;
  cursor: pointer;
  display: inline-block;
  width: 50px;
  height: 50px!important;
  margin-left: 10px;
  margin-top: -3px;
  border-radius: 100%!important;
  z-index: 1;
  transition: .3s;
}
.author_list .author_list_pp {
  position: absolute;
  display: inline-block;
  width: 50px;
  height:auto;
  margin-left: 10px;
  margin-top: -3px;
  cursor: pointer;
  z-index:10

}

.author_list .author_list_pp img {
  transition: .2s ease;
}

.author_list .author_list_pp img {
  height: 50px!important;
}

.author_list_pp img {
  width: 100%;
  border-radius: 100% !important;
  position: relative;
  z-index:1;
}
.author_list .author_list_info {
  font-weight: 700;
  padding-left: 70px;
}

.author_list .author_list_info .bot {
    color: #fff;
    display: block;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.2em;
}

.author_list .author_list_info span {
    cursor: pointer;
    color: #fff;
}

h2.s2 {
    font-size: 30px;
    margin-bottom: 20px;
}
h2 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 36px;
  line-height: 46px;
}
.bk {
  color: #333;
}
  #bk2 ul, #bk2 li {
    margin: 0; padding: 0;
}

